import get from "lodash/get";

const errorLocations = {
  graphQL: "response.errors",
  axios: "response.data.message",
  status: "err.status",
  default: "err.response.data",
};

const graphQLExtensionLocations = {
  code: "extensions.code",
  statusCode: "extensions.exception.response.statusCode",
  status: "extensions.exception.response.status",
};

export interface ExtendedGraphQLError {
  message: string;
  code?: string;
  statusCode?: number;
  status?: string;
}

export const getError = (
  err: Error,
  type: keyof typeof errorLocations = "default"
) => {
  const errorLocation = errorLocations[type];
  let message: string | undefined = get(
    err,
    errorLocation,
    "An error has been detected with your network, please check your connection and refresh the page."
  );

  if (Array.isArray(message)) {
    message = message[0]?.message || message[0];
  }

  return message;
};

const getErrorMessage = getError;

const extractErrorProperties = (
  error: Error
): Pick<ExtendedGraphQLError, "code" | "statusCode" | "status"> => {
  const code = get(error, graphQLExtensionLocations.code) as string | undefined;
  const statusCode = get(error, graphQLExtensionLocations.statusCode) as
    | number
    | undefined;
  const status = get(error, graphQLExtensionLocations.status) as
    | string
    | undefined;

  return {
    code,
    statusCode,
    status,
  };
};

const getErrors = (err: Error) => {
  const errors = get(err, errorLocations.graphQL) as unknown;
  return (Array.isArray(errors) ? errors[0] : errors) as Error;
};

export const getGraphQLError = (err: Error): ExtendedGraphQLError => {
  const message = getErrorMessage(err, "graphQL");
  const error = getErrors(err);

  const { code, statusCode, status } = extractErrorProperties(error);

  return {
    code,
    statusCode,
    status,
    message,
  };
};

export const extractGraphQLErrorProperties = getGraphQLError;

export const TaxRateStates = [
  { name: 'ALABAMA', abbreviation: 'AL', taxAmount: 5 },
  { name: 'ALASKA', abbreviation: 'AK', taxAmount: 0 },
  { name: 'ARIZONA', abbreviation: 'AZ', taxAmount: 4.54 },
  { name: 'ARKANSAS', abbreviation: 'AR', taxAmount: 7.0 },
  { name: 'CALIFORNIA', abbreviation: 'CA', taxAmount: 13.3 },
  { name: 'COLORADO', abbreviation: 'CO', taxAmount: 4.63 },
  { name: 'CONNECTICUT', abbreviation: 'CT', taxAmount: 6.7 },
  { name: 'DELAWARE', abbreviation: 'DE', taxAmount: 6.75 },
  { name: 'DISTRICT OF COLUMBIA', abbreviation: 'DC', taxAmount: 8.95 },
  { name: 'FLORIDA', abbreviation: 'FL', taxAmount: 0 },
  { name: 'GEORGIA', abbreviation: 'GA', taxAmount: 6.0 },
  { name: 'HAWAII', abbreviation: 'HI', taxAmount: 11.0 },
  { name: 'IDAHO', abbreviation: 'ID', taxAmount: 7.4 },
  { name: 'ILLINOIS', abbreviation: 'IL', taxAmount: 5.0 },
  { name: 'INDIANA', abbreviation: 'IN', taxAmount: 3.4 },
  { name: 'IOWA', abbreviation: 'IA', taxAmount: 8.98 },
  { name: 'KANSAS', abbreviation: 'KS', taxAmount: 4.9 },
  { name: 'KENTUCKY', abbreviation: 'KY', taxAmount: 6 },
  { name: 'LOUISIANA', abbreviation: 'LA', taxAmount: 6 },
  { name: 'MAINE', abbreviation: 'ME', taxAmount: 5.25 },
  { name: 'MARYLAND', abbreviation: 'MD', taxAmount: 5.75 },
  { name: 'MASSACHUSETTS', abbreviation: 'MA', taxAmount: 5.25 },
  { name: 'MICHIGAN', abbreviation: 'MI', taxAmount: 4.25 },
  { name: 'MINNESOTA', abbreviation: 'MN', taxAmount: 9.85 },
  { name: 'MISSISSIPPI', abbreviation: 'MS', taxAmount: 4.25 },
  { name: 'MISSOURI', abbreviation: 'MO', taxAmount: 6 },
  { name: 'MONTANA', abbreviation: 'MT', taxAmount: 6.9 },
  { name: 'NEBRASKA', abbreviation: 'NE', taxAmount: 6.84 },
  { name: 'NEVADA', abbreviation: 'NV', taxAmount: 0 },
  { name: 'NEW HAMPSHIRE', abbreviation: 'NH', taxAmount: 5 },
  { name: 'NEW JERSEY', abbreviation: 'NJ', taxAmount: 8.97 },
  { name: 'NEW MEXICO', abbreviation: 'NM', taxAmount: 4.9 },
  { name: 'NEW YORK', abbreviation: 'NY', taxAmount: 8.82 },
  { name: 'NORTH CAROLINA', abbreviation: 'NC', taxAmount: 7.75 },
  { name: 'NORTH DAKOTA', abbreviation: 'ND', taxAmount: 3.99 },
  { name: 'OHIO', abbreviation: 'OH', taxAmount: 5.925 },
  { name: 'OKLAHOMA', abbreviation: 'OK', taxAmount: 5.25 },
  { name: 'OREGON', abbreviation: 'OR', taxAmount: 9.9 },
  { name: 'PENNSYLVANIA', abbreviation: 'PA', taxAmount: 3.07 },
  { name: 'RHODE ISLAND', abbreviation: 'RI', taxAmount: 5.99 },
  { name: 'SOUTH CAROLINA', abbreviation: 'SC', taxAmount: 7 },
  { name: 'SOUTH DAKOTA', abbreviation: 'SD', taxAmount: 0 },
  { name: 'TENNESSEE', abbreviation: 'TN', taxAmount: 6 },
  { name: 'TEXAS', abbreviation: 'TX', taxAmount: 0 },
  { name: 'UTAH', abbreviation: 'UT', taxAmount: 5 },
  { name: 'VERMONT', abbreviation: 'VT', taxAmount: 8.95 },
  { name: 'VIRGINIA', abbreviation: 'VA', taxAmount: 5.75 },
  { name: 'WASHINGTON', abbreviation: 'WA', taxAmount: 0 },
  { name: 'WEST VIRGINIA', abbreviation: 'WV', taxAmount: 6.5 },
  { name: 'WISCONSIN', abbreviation: 'WI', taxAmount: 7.65 },
  { name: 'WYOMING', abbreviation: 'WY', taxAmount: 0 },
];

export const usaStates = TaxRateStates.map(
  ({ name, abbreviation, taxAmount }) => ({
    label: capitalizeFirstLetter(name.toLowerCase()),
    value: name,
    abbreviation: abbreviation,
    taxAmount: taxAmount,
  }),
);

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

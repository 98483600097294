/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import { useEffect, useState } from "react";

import { SeatsIOSeat } from "~components/reservation/constants";
import { EventQuery, MembershipQuery } from "~graphql/sdk";

type SeatsWithQuantity = SeatsIOSeat & {
  quantity: number;
};

export const useMultibuy = (
  promotions:
    | EventQuery["event"]["multiBuyPromotions"]
    | MembershipQuery["membership"]["multiBuyPromotions"],
  seats: any[]
) => {
  const [activePromotions, setActivePromotions] = useState([]);
  const [selectedPromotion, setSelectedPromotion] = useState([
    activePromotions?.[0] || [],
  ]);

  useEffect(() => {
    if (promotions && seats.length > 0) {
      promotions.forEach((promo) => {
        const getType = promo?.getTicketType || promo?.getMembershipType;
        const buyType = promo?.buyTicketType || promo?.buyMembershipType;

        const buySeats = seats?.filter(
          ({ ticketType, membershipType }) =>
            (ticketType?.value ||
              ticketType?.id ||
              membershipType?.id ||
              ticketType) === buyType.id
        );

        const getSeats = seats?.filter(
          ({ ticketType, membershipType }) =>
            (ticketType?.value ||
              ticketType?.id ||
              membershipType?.id ||
              ticketType) === getType.id
        );

        const buyTotal = buySeats?.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        );

        const buySeatQty = Math.floor(buyTotal / promo.buyQuantity);

        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        const getTotal = getSeats?.reduce(
          (acc, curr) => acc + curr.quantity,
          0
        );

        const getSeatQty = Math.ceil(getTotal / promo.getQuantity);

        const giftedQty =
          buySeatQty * promo.getQuantity <= getTotal
            ? buySeatQty * promo.getQuantity
            : getTotal;

        const foundPromo = activePromotions.find(
          ({ promotion }) => promotion.id === promo.id
        );

        if (getSeatQty > 0 && buySeatQty > 0) {
          if (foundPromo) {
            if (
              (foundPromo.buySeatQty !== buySeatQty &&
                foundPromo.getSeatQty !== getSeatQty) ||
              foundPromo.giftedQty !== giftedQty
            ) {
              setActivePromotions([
                ...(activePromotions?.filter(
                  ({ promotion }) => promotion.id !== promo.id
                ) ?? []),
                {
                  promotion: promo,
                  giftedQty,
                  getType,
                  getSeatQty,
                  buySeatQty,
                },
              ]);
            }
          } else {
            setActivePromotions([
              ...activePromotions,
              {
                promotion: promo,
                giftedQty,
                getType,
                getSeatQty,
                buySeatQty,
              },
            ]);
          }
        } else {
          if (foundPromo) {
            setActivePromotions([
              ...(activePromotions?.filter(
                ({ promotion }) => promotion.id !== promo.id
              ) ?? []),
            ]);
          }
        }
      });
    }
  }, [promotions, seats]);

  return { activePromotions, selectedPromotion, setSelectedPromotion };
};

import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useReducer,
  useState,
} from "react";
import { EventTicket } from "~features/tickets/types";

interface TicketNamingState {
  showModal: boolean;
  ticket: EventTicket;
  initialTicket: EventTicket;
  ticketType: EventTicket["lineItem"]["type"];
}

export enum TicketNamingActionTypes {
  OPEN_MODAL = "OPEN_MODAL",
  CLOSE_MODAL = "CLOSE_MODAL",
  SET_TICKET = "SET_TICKET",
  UNSET_TICKET = "UNSET_TICKET",
  INIT_TICKET = "INIT_TICKET",
}

type TicketNamingActions =
  | {
      type:
        | TicketNamingActionTypes.OPEN_MODAL
        | TicketNamingActionTypes.CLOSE_MODAL;
    }
  | {
      type:
        | TicketNamingActionTypes.SET_TICKET
        | TicketNamingActionTypes.INIT_TICKET
        | TicketNamingActionTypes.UNSET_TICKET;
      payload: EventTicket;
    };

interface TicketNamingContextProps extends TicketNamingState {
  dispatch: (input: TicketNamingActions) => void;
  page: "edit" | "view" | "transfer" | "share";
  setPage: Dispatch<SetStateAction<TicketNamingContextProps["page"]>>;
  backPage: () => void;
  clearPages: () => void;
}

interface Props {
  children: ReactNode;
}

const initialState: TicketNamingState = {
  showModal: false,
  ticket: undefined,
  initialTicket: undefined,
  ticketType: undefined,
};

function reducer(state: TicketNamingState, action: TicketNamingActions) {
  switch (action.type) {
    case TicketNamingActionTypes.OPEN_MODAL:
      return { ...state, showModal: true };
    case TicketNamingActionTypes.CLOSE_MODAL:
      return {
        ...state,
        showModal: false,
        ticket: undefined,
        ticketType: undefined,
        initialTicket: undefined,
      };
    case TicketNamingActionTypes.UNSET_TICKET:
      return {
        ...state,
        ticket: undefined,
        initialTicket: action.payload,
      };
    case TicketNamingActionTypes.SET_TICKET:
      return {
        ...state,
        ticket: action.payload,
      };
    case TicketNamingActionTypes.INIT_TICKET:
      return {
        ...state,
        ticketType: action.payload?.lineItem?.type,
        initialTicket: action.payload,
      };
    default:
      throw new Error("Action was not specified");
  }
}

const TicketNamingContext = createContext<TicketNamingContextProps>(undefined);

const TicketNamingProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const [page, _setPage] = useState<TicketNamingContextProps["page"]>("view");
  const [prevPageList, setPrevPageList] = useState<
    TicketNamingContextProps["page"][]
  >([]);

  const backPage = () => {
    const prevPage = prevPageList.pop();

    setPage(prevPage);
    setPrevPageList(prevPageList);

    // Close modal if we have no prev pages
    if (prevPageList.length === 0) {
      dispatch({ type: TicketNamingActionTypes.CLOSE_MODAL });
      dispatch({
        type: TicketNamingActionTypes.UNSET_TICKET,
        payload: undefined,
      });
    }
  };

  const clearPages = () => {
    setPrevPageList([]);
    _setPage("view");
  };

  const setPage = (newPage: TicketNamingContextProps["page"]) => {
    setPrevPageList(prevPageList.concat(page));
    _setPage(newPage);
  };

  return (
    <TicketNamingContext.Provider
      value={{
        ...state,
        page,
        setPage,
        backPage,
        clearPages,
        dispatch,
      }}
    >
      {children}
    </TicketNamingContext.Provider>
  );
};

export { TicketNamingProvider, TicketNamingContext };

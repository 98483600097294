import { ToastAlert } from 'flicket-ui';
import { toast } from 'react-toastify';

// import { Icon } from '~components';

// const icons = {
//   success: <Icon icon="info" />,
//   warning: <Icon icon="info" />,
//   error: <Icon icon="error" color="error" />,
// };

export const showToast = (
  msg: string,
  type?: 'success' | 'warning' | 'error',
) => {
  toast(() => <ToastAlert msg={msg} type={type} />);
};

import React, { FC, ReactElement, ReactNode } from "react";
import { Flex, Container, Box, System, SystemProps } from "flicket-ui";
import { ExtendedFile } from "~graphql/sdk";
import { ContentContainer } from "./common.contentContainer";
import Image from "~components/common/Image";

type PageContainerProps = {
  title?: ReactElement;
  footer: ReactElement;
  children: ReactNode;
  bannerImage?: ExtendedFile | null;
  backgroundImage?: ExtendedFile | null;
  backgroundImageFullPage?: ExtendedFile | null;
  titleText?: string;
  containerProps?: SystemProps;
};

export const PageContainer: FC<PageContainerProps> = ({
  titleText,
  title,
  footer,
  children,
  bannerImage,
  backgroundImage,
  backgroundImageFullPage,
  containerProps,
}) => {
  const hasFullPageBackground = !!backgroundImageFullPage;

  const Footer = React.cloneElement(footer, {
    hasFullPageBackground,
  });

  return (
    <ContentContainer
      variant="content"
      image={backgroundImage}
      imageFullPage={backgroundImageFullPage}
    >
      <Flex flexDir="column" pb={{ _: 2, md: 0 }} flex="1">
        {title}

        <Container
          d={{ _: "block", md: "block" }}
          innerProps={{
            position: hasFullPageBackground ? "relative" : "static",
            minHeight: { _: 0, md: "320px" },
            pt: { _: 0, md: bannerImage ? 0 : 4 },
            // @ts-expect-error override
            pb: { _: 4, md: hasFullPageBackground ? "150px" : 8 },
            overflow: "hidden",
          }}
          mb={hasFullPageBackground ? 15 : 10}
          {...containerProps}
        >
          {bannerImage && (
            <Image
              image={bannerImage}
              alt={titleText}
              priority={true}
              // @ts-expect-error override
              m={{ _: 0, md: "0 -32px 32px -32px" }}
            />
          )}

          {children}

          {hasFullPageBackground && Footer}
        </Container>
      </Flex>
      {!hasFullPageBackground && Footer}
    </ContentContainer>
  );
};

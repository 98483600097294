import * as Sentry from "@sentry/nextjs";
import { GraphQLClient } from "graphql-request";
import { GetStaticProps } from "next";
import { graphUrl } from "~config";
import { getSdk, SdkFunctionWrapper } from "~graphql/sdk";
import { extractGraphQLErrorProperties } from "~lib/helpers";

export const client = (
  orgId: string | undefined = undefined,
  host: string | undefined = undefined,
  recaptchaToken: string | undefined = undefined,
  transactionId: string | undefined = undefined
) =>
  new GraphQLClient(graphUrl, {
    credentials: "include",
    headers: {
      ...(orgId
        ? {
            "Flicket-Org-Id": orgId,
          }
        : {}),
      ...(host
        ? {
            origin: host,
          }
        : {}),
      ...(process.env.VERCEL_USER_TOKEN
        ? { "user-token": process.env.VERCEL_USER_TOKEN }
        : { "user-token": "PLACEHOLDER" }),
      ...(recaptchaToken ? { "g-recaptcha-response": recaptchaToken } : {}),
      ...(transactionId ? { "transaction-id": transactionId } : {}),
    },
  });

const sentryWrapper: SdkFunctionWrapper = async <T>(
  action: () => Promise<T>
) => {
  return action().catch((error) => {
    const { status, statusCode, code } = extractGraphQLErrorProperties(
      error as Error
    );

    Sentry.captureException(error, {
      tags: {
        status,
        statusCode,
        code,
      },
    });

    throw error;
  });
};

export interface SDKOptions {
  orgId?: string;
  host?: string;
  key?: string;
  value?: string;
  recaptchaToken?: string;
  isServer?: boolean;
}

export type SDKMethod = keyof ReturnType<typeof sdk>;
export type SDKGeneratorFn = (
  method?: SDKMethod
) => Promise<
  (options: Omit<SDKOptions, "recaptchaToken">) => ReturnType<typeof sdk>
>;

export const sdk = ({
  orgId,
  host,
  key,
  value,
  recaptchaToken,
  isServer = false,
}: SDKOptions = {}) => {
  const transactionId = Math.random().toString(36).substring(2, 9);
  Sentry.configureScope((scope) => {
    scope.setTag("transaction-id", transactionId);
  });

  const gqlClient = client(orgId, host, recaptchaToken, transactionId);

  if (key && value) {
    gqlClient.setHeader(key, value);
  }

  // Hack to get through the Firewall when calling the sdk from a server function.
  // TODO: investigate a beetter way to do this and remove.
  if (isServer) {
    gqlClient.setHeader("user-agent", "Mozilla");
  }

  return getSdk(gqlClient, sentryWrapper);
};

export const getStaticPropsWithSDK = async (
  func: (sdk: ReturnType<typeof getSdk>) => ReturnType<GetStaticProps>
): Promise<ReturnType<GetStaticProps>> => {
  const staticSDK = getSdk(client());
  return func(staticSDK);
};

import React, { ReactNode } from 'react';
import div100VH from 'react-div-100vh';
import styled from 'styled-components';

type BodyProps = {
  children: ReactNode;
};

const StyledBody = styled(div100VH)`
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: ${(p) => p.theme.breakpoints.md}) {
    overflow: hidden;
  }
`;

export const Body = ({ children }: BodyProps) => (
  <StyledBody id="my-body">{children}</StyledBody>
);

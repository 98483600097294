import { Box, Link, Text, SystemProps } from "flicket-ui";
import Swiper from "react-id-swiper";
import styled from "styled-components";
import NextImage from "next/future/image";

import { Event } from "~graphql/sdk";
import { formatDate, getImage } from "~lib/helpers";

interface FeaturedEventProps extends SystemProps {
  events: Partial<Event>[];
  sliderParams?: any;
  slidesPerView?: number | string;
  breakpoints?: object;
}

const StyledSwiper = styled.div`
  .swiper-container {
    margin-right: -16px;
    margin-left: -16px;

    padding-right: 16px;
    padding-left: 16px;
  }

  display: block;

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    display: none;
  }
`;

const FeaturedEvent = styled.div`
  position: relative;
  width: 100%;
  height: 160px;

  overflow: hidden;

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  border-radius: 12px;

  transition: box-shadow 0.2s;

  &:hover {
    opacity: 1;

    box-shadow: ${(p) => p.theme.shadows.hover};
  }

  .card-background::after {
    content: "";

    position: absolute;
    width: 100%;
    height: 100%;

    background: linear-gradient(
      62.53deg,
      #1d1c20 0%,
      rgba(29, 28, 32, 0.25) 100%
    );
  }

  @media (min-width: ${(p) => p.theme.breakpoints.md}) {
    height: 200px;
  }
`;

const EventInfo = styled((props) => <Box {...props} />)`
  position: absolute;
  bottom: 12px;
  left: 12px;
  max-width: calc(100% - 24px);

  color: ${(p) => p.theme.colors.white};
`;

const EventDate = styled((props) => <Box {...props} />)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: 0;
  left: 0;

  min-width: 52px;
  min-height: 69px;

  padding: 8px;

  background-color: ${(p) => p.theme.colors.S300};

  color: ${(p) => p.theme.colors.white};

  border-radius: 0px 0 12px 0;
`;

const StyledLink = styled(Link)`
  position: absolute;
  width: 100%;
  height: 100%;

  z-index: ${(p) => p.theme.zIndices.docked};
`;

const StyledContainer = styled(Box)`
  > div {
    margin-right: ${(p) => p.theme.space[5]}px;
  }

  > div:last-of-type {
    margin-right: 0;
  }
`;

export const FeaturedEvents = ({
  events,
  slidesPerView = 1.5,
  sliderParams,
  ...props
}: FeaturedEventProps) => {
  const params = {
    spaceBetween: 16,
    watchOverflow: true,
    slidesPerView: slidesPerView,
    rebuildOnUpdate: true,
    ...sliderParams,
  };

  const renderCards = () =>
    events?.map(({ id, thumbnail, startDate, endDate, title, venue }) => (
      <FeaturedEvent key={id}>
        <Box
          position="absolute"
          left="0"
          right="0"
          top="0"
          bottom="0"
          className="card-background">
            {getImage(thumbnail) && <NextImage
                src={getImage(thumbnail)}
                fill={true}
                alt={`${title} ${venue.name} featured image`}
                priority={true}
                quality={75}
                style={{ objectFit: "cover", objectPosition: "center" }}
            />  }
        </Box>
        <StyledLink to="/events/[eventId]" linkAs={`/events/${id}`} />
        <EventDate>
          <Text fontWeight="extraBold" fontSize={7} lineHeight={"100%" as any}>
            {/** day */}
            {endDate &&
            formatDate(startDate as string, "dd") ===
              formatDate(endDate as string, "dd")
              ? formatDate(startDate as string, "dd")
              : `${formatDate(startDate as string, "dd")} - ${formatDate(
                  endDate as string,
                  "dd"
                )}`}
          </Text>
          <Text
            fontSize={4}
            fontWeight="extraBold"
            lineHeight={"100%" as any}
            variant="uppercase"
          >
            {/** month */}
            {formatDate(startDate as string, "LLL")}
          </Text>
        </EventDate>
        <EventInfo>
          <Text fontSize={4} fontWeight="extraBold" variant="uppercase">
            {title}
          </Text>
          <Text fontSize={2} fontWeight="demiBold" lineHeight="medium" ellipsis>
            {`${venue?.name}, ${
              venue?.address?.city ? venue?.address?.city : ""
            }`}
          </Text>
        </EventInfo>
      </FeaturedEvent>
    ));

  return (
    <>
      <StyledSwiper>
        <Swiper {...params} enableRein>
          {renderCards()}
        </Swiper>
      </StyledSwiper>
      <StyledContainer d={{ _: "none", md: "flex" }} {...props}>
        {renderCards()}
      </StyledContainer>
    </>
  );
};

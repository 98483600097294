import {
  CreateOrderInput,
  EventQuery,
  GetReleaseByIdQuery,
  GetReleaseBySlugQuery,
} from "~graphql/sdk";
import { sdk, SDKOptions } from "~lib";
import { handlePromise } from "~lib/helpers";

export const createOrder = async (
  sdkFn: typeof sdk,
  orgId: string,
  input: CreateOrderInput
) => handlePromise(async () => sdkFn({ orgId }).createOrder({ input }));

export type Event = EventQuery["event"];

interface GetEventProps {
  orgId: string;
  eventId: string;
  sdkOptions?: SDKOptions;
}

export const _getEvent = async ({
  orgId,
  eventId,
  sdkOptions = {},
}: GetEventProps): Promise<Event> =>
  sdk({ orgId, ...sdkOptions })
    .event({ id: eventId })
    .then(({ event }) => event);

export const getEvent = async (
  _: string,
  orgId: string,
  eventId: string
): Promise<Event> => _getEvent({ orgId, eventId });

export const getMembership = async (
  _: string,
  orgId: string,
  membershipId: string
) =>
  sdk({ orgId })
    .membership({ id: membershipId })
    .then(({ membership }) => membership);

export type Release =
  | GetReleaseByIdQuery["release"]
  | GetReleaseBySlugQuery["getReleaseBySlug"];

interface GetReleaseProps extends GetEventProps {
  releaseId: string;
  slug?: string;
  activeOnly?: boolean;
}

export const _getRelease = async ({
  orgId,
  eventId,
  releaseId = null,
  slug = null,
  // Defaults to false in the backend
  activeOnly = false,
  sdkOptions = {},
}: GetReleaseProps): Promise<Release> => {
  if (releaseId) {
    const id = releaseId.trim();
    return sdk({ orgId, ...sdkOptions })
      .getReleaseById({ id })
      .then(({ release }) => release);
  } else {
    return sdk({ orgId, ...sdkOptions })
      .getReleaseBySlug({
        slug: slug ? slug.trim() : null,
        eventId,
        activeOnly,
      })
      .then(({ getReleaseBySlug }) => getReleaseBySlug);
  }
};

export const getRelease = async (
  _: string,
  orgId: string,
  eventId: string,
  releaseId: string,
  slug: string,
  activeOnly: boolean
): Promise<Release> =>
  _getRelease({ orgId, eventId, releaseId, slug, activeOnly });

export const getReleaseById = async (_: string, orgId: string, id: string) =>
  sdk({ orgId })
    .getReleaseById({ id })
    .then(({ release }) => release);

export const getMyTickets = async (_: string, orgId: string, eventId: string) =>
  sdk({ orgId }).myEventTickets({ id: eventId });

export const getMyMembershipTickets = async (_: string, orgId: string) =>
  sdk({ orgId }).myMembershipTickets();

export const getTicketsByIds = async (_: string, orgId: string, ids: string) =>
  sdk({ orgId }).getTicketsByIds({ ids });

export const getUser = async (_: string, orgId: string, userId: string) =>
  sdk({ orgId })
    .user({ id: userId })
    .then(({ user }) => user);

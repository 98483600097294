/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-return */
declare interface DataLayerWindow extends Window {
  fbq: any;
}
declare const window: DataLayerWindow;

export const pageviewFB = () => {
  if (typeof window.fbq === "undefined") {
    return;
  }
  window.fbq("track", "PageView");
};

// https://developers.facebook.com/docs/facebook-pixel/advanced/
export const eventFB = (name, options = {}) => {
  if (typeof window.fbq === "undefined") {
    return;
  }
  window.fbq("track", name, options);
};

export const trackPurchaseFB = (order, organization) => {
  if (typeof window.fbq === "undefined") {
    return;
  }
  window.fbq("track", "Purchase", {
    transaction_id: order?.orderNumber,
    affiliation: order?.event?.title || order?.membership?.name,
    value: order?.total,
    tax: order?.totalTax,
    shipping: order?.deliveryFee,
    currency: organization?.currency,
    items: order?.lineItems?.edges
      ?.filter(({ node }) => node?.total > 0)
      ?.map(({ node }) => ({
        item_name: node?.name,
        item_id:
          node.ticketType?.id ||
          node.membershipType?.id ||
          node.eventAddon?.id ||
          node.membershipAddon?.id,
        item_price: node?.price,
        item_category: node?.type,
        quantity: node?.quantity,
      })),
    customerDetails: {
      billing_first_name: order.user ? order.user.firstName : null,
      billing_last_name: order.user ? order.user.lastName : null,
      billing_email: order.user ? order.user.email : null,
      billing_phone: order.user ? order.user.phoneNumber : null,
      billing_postal_code: order.user
        ? order.user.shippingAddress?.postalCode
        : null,
      billing_state: order.user ? order.user.shippingAddress?.postalCode : null,
      billing_city: order.user ? order.user.shippingAddress?.city : null,
      billing_country: order.user ? order.user.shippingAddress?.country : null,
    },
  });
};

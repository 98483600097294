import React, { ReactNode, useContext } from "react";

import { Box, SystemProps, Flex } from "flicket-ui";
import { BaseContext } from "~context";

import { getImage } from "~lib/helpers";
import { ExtendedFile } from "~graphql/sdk";
import Image from "next/future/image";

interface ContentContainerProps extends SystemProps {
  children: ReactNode;
  innerProps?: SystemProps;
  variant?: "content";
  image?: ExtendedFile | undefined | null;
  imageFullPage?: ExtendedFile | undefined | null;
}

export const ContentContainer = ({
  children,
  innerProps = {},
  variant,
  image,
  imageFullPage,
  ...props
}: ContentContainerProps) => {
  const { organization } = useContext(BaseContext);

  const bgImage =
    imageFullPage ??
    image ??
    organization.branding?.fullBackground ??
    organization.branding?.background;

  const fullSizedBackground =
    !!imageFullPage || bgImage === organization.branding?.fullBackground;

  return (
    <Flex
      position="relative"
      flex="1"
      flexDir="column"
      bg={variant === "content" && { _: "white", md: "N100" }}
      {...props}
    >
      <Box
        w="100%"
        d={{ _: "none", md: "flex" }}
        zIndex="base"
        height={fullSizedBackground ? "100%" : "360px"}
        position={fullSizedBackground ? "fixed" : "absolute"}
        top={fullSizedBackground ? 0 : "auto"}
        backgroundColor={bgImage ? "transparent" : "P300"}
        backgroundPosition="center"
      >
        {bgImage && (
          <Image
            src={getImage(bgImage, "")}
            fill={true}
            alt="background"
            style={{ objectFit: "cover" }}
            quality={fullSizedBackground ? 90 : 75}
          />
        )}
      </Box>
      <Flex
        position="relative"
        zIndex="docked"
        pt={variant === "content" ? { _: 2, md: 5 } : 9}
        flex="1"
        flexDir="column"
        {...innerProps}
      >
        {children}
      </Flex>
    </Flex>
  );
};

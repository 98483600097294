import { useContext } from "react";
import { BaseContext } from "~context";
import { Integration } from "~graphql/sdk";
import { calculateLaybuyPrice, OrganizationFeatures } from "~lib";

export const useLaybuyPriceCalculation = (
  totalPrice: number,
  laybuyIntegration: Partial<Integration>
) => {
  const { organization } = useContext(BaseContext);

  const showHiddenFees = organization.features?.includes(
    OrganizationFeatures.ShowHiddenFees
  );

  const laybuyPrice = calculateLaybuyPrice(totalPrice, {
    showHiddenFees,
    fee: laybuyIntegration?.transactionFee,
    type: laybuyIntegration?.transactionFeeType,
  });

  return {
    laybuyPrice,
    showHiddenFees,
    laybuyIntegration,
  };
};

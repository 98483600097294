// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { useEffect, useState, useCallback } from "react";

import {
  CoverTypes,
  AlcoholFreeZoneTypes,
  SeatsIOZone,
} from "~components/reservation/constants";
import { Zone, Release } from "~graphql/sdk";

type ZoneFiltersHookProps = {
  zones: SeatsIOZone[];
  releasedZones: any[];
  opts: {
    coverType: CoverTypes;
    alcoholFreeZone: AlcoholFreeZoneTypes;
  };
};

type ZoneFiltersHookResponse = {
  filteredZones: any;
};

type ZoneFiltersHooks = ({
  zones,
  opts: { coverType, alcoholFreeZone },
}: ZoneFiltersHookProps) => ZoneFiltersHookResponse;

/**
 * currently we only have two type of tags:
 *  - c = 'covered'
 *  - f = 'family zone' (a.k.a alcohol free zone)
 */
const includesTag = (array: string[], tag: "c" | "f") => array.includes(tag);

const isCovered = (coverType: CoverTypes) => coverType === CoverTypes.COVERED;
const isUnCovered = (coverType: CoverTypes) =>
  coverType === CoverTypes.UNCOVERED;
const isCoverAll = (coverType: CoverTypes) => coverType === CoverTypes.ALL;

const isAlcoholFreeZone = (alcoholFreeZone: AlcoholFreeZoneTypes) =>
  alcoholFreeZone === AlcoholFreeZoneTypes.YES;
const noAlcoholFreeZone = (alcoholFreeZone: AlcoholFreeZoneTypes) =>
  alcoholFreeZone === AlcoholFreeZoneTypes.NO;
const isFamilyAll = (alcoholFreeZone: AlcoholFreeZoneTypes) =>
  alcoholFreeZone === AlcoholFreeZoneTypes.NO_PREFERENCE;

const findZones = (
  zones: SeatsIOZone[],
  releasedZones: Release["releaseZones"],
  coverType: CoverTypes,
  alcoholFreeZone: AlcoholFreeZoneTypes
) => {
  const allZones = [];

  zones?.forEach((zone) => {
    if (
      !releasedZones?.find(
        ({ zone: rZone, isActive }) => isActive && rZone.name === zone.name
      )
    ) {
      return; // do nothing if the zone is not released
    }

    /**
     * filters:
     *  - coverType === all
     *  - AlcoholFreeZone === no-preference
     */
    if (isCoverAll(coverType) && isFamilyAll(alcoholFreeZone)) {
      return allZones.push({
        ...zone,
      });
    }

    /**
     * filters:
     *  - coverType === all
     *  - AlcoholFreeZone === yes
     */
    if (
      isCoverAll(coverType) &&
      isAlcoholFreeZone(alcoholFreeZone) &&
      includesTag(zone.tags, "f")
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter((section) =>
          includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === all
     *  - AlcoholFreeZone === no
     */
    if (
      isCoverAll(coverType) &&
      noAlcoholFreeZone(alcoholFreeZone) &&
      (!includesTag(zone.tags, "f") || zone.sections.length > zone.tags.length)
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) => !includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === covered
     *  - AlcoholFreeZone === no-preference
     */
    if (
      isCovered(coverType) &&
      isFamilyAll(alcoholFreeZone) &&
      includesTag(zone.tags, "c")
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter((section) =>
          includesTag(section.tags, "c")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === covered
     *  - AlcoholFreeZone === yes
     */
    if (
      isCovered(coverType) &&
      isAlcoholFreeZone(alcoholFreeZone) &&
      includesTag(zone.tags, "c") &&
      includesTag(zone.tags, "f")
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) =>
            includesTag(section.tags, "c") && includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === covered
     *  - AlcoholFreeZone === no
     */
    if (
      isCovered(coverType) &&
      noAlcoholFreeZone(alcoholFreeZone) &&
      includesTag(zone.tags, "c") &&
      (!includesTag(zone.tags, "f") || zone.sections.length > zone.tags.length)
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) =>
            includesTag(section.tags, "c") && !includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === uncovered
     *  - AlcoholFreeZone === no-preference
     */
    if (
      isUnCovered(coverType) &&
      isFamilyAll(alcoholFreeZone) &&
      (!includesTag(zone.tags, "c") || zone.sections.length > zone.tags.length)
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) => !includesTag(section.tags, "c")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === uncovered
     *  - AlcoholFreeZone === yes
     */
    if (
      isUnCovered(coverType) &&
      isAlcoholFreeZone(alcoholFreeZone) &&
      (!includesTag(zone.tags, "c") ||
        zone.sections.length > zone.tags.length) &&
      includesTag(zone.tags, "f")
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) =>
            !includesTag(section.tags, "c") && includesTag(section.tags, "f")
        ),
      });
    }

    /**
     * filters:
     *  - coverType === uncovered
     *  - AlcoholFreeZone === no
     */
    if (
      isUnCovered(coverType) &&
      noAlcoholFreeZone(alcoholFreeZone) &&
      (!includesTag(zone.tags, "c") ||
        zone.sections.length > zone.tags.length) &&
      (!includesTag(zone.tags, "f") || zone.sections.length > zone.tags.length)
    ) {
      return allZones.push({
        ...zone,
        sections: zone?.sections?.filter(
          (section) =>
            !includesTag(section.tags, "c") && !includesTag(section.tags, "f")
        ),
      });
    }
  });

  return allZones;
};

// TODO: why does this need to be done client side?
export const useZoneFilters: ZoneFiltersHooks = ({
  zones,
  releasedZones,
  opts: { coverType, alcoholFreeZone },
}) => {
  const [filteredZones, setFilteredZones] = useState<Zone[]>(undefined);

  const setZones = useCallback(
    (newZone: Zone[]) => setFilteredZones(newZone),
    []
  );

  useEffect(() => {
    if (zones) {
      setZones(findZones(zones, releasedZones, coverType, alcoholFreeZone));
    }
  }, [zones, releasedZones, coverType, alcoholFreeZone]);

  return {
    filteredZones,
  };
};

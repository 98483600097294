import React, { useState, ReactNode } from "react";

import { Flex, Text } from "flicket-ui";
import { useIsMobile } from "~hooks";
import { Icon, IconProps } from "./Icon";

type TabItemProps = {
  id: string;
  title: string;
  icon?: IconProps["icon"];
  content: ReactNode;
};

type TabProps = {
  initialTab?: TabItemProps | string;
  items: TabItemProps[];
  tabProps?: any;
};

export const Tabs = ({ initialTab, items, tabProps = {} }: TabProps) => {
  const isMobile = useIsMobile();
  const [activeTab, setActiveTab] = useState<TabItemProps>(
    typeof initialTab === "string"
      ? items.find(({ id }) => id === initialTab)
      : initialTab ?? items[0]
  );

  return (
    <>
      <Flex borderBottom="1px solid" borderColor="N200" w="100%">
        {items.map(({ id, title, content, ...props }) => (
          <Flex
            key={id}
            onClick={() =>
              setActiveTab({
                id,
                title,
                content,
                // eslint-disable-next-line react/prop-types
                ...(props?.icon && { icon: props?.icon }),
              })
            }
            cursor="pointer"
            alignItems="center"
            pb={1}
            borderBottom="4px solid"
            color={activeTab.id === id ? "N800" : "N500"}
            borderColor={activeTab.id === id ? "P300" : "transparent"}
            mr={{
              _: items[items.length - 1].id === id ? 0 : 3,
              md: items[items.length - 1].id === id ? 0 : 7,
            }}
            ml={{
              _: 0,
              md: items[0].id === id ? 4 : 0,
            }}
          >
            {/* eslint-disable-next-line react/prop-types */}
            {props?.icon && !isMobile && (
              //eslint-disable-next-line react/prop-types
              <Icon fontSize={4} icon={props.icon} mr={1} />
            )}
            <Text fontSize={3} fontWeight="extraBold">
              {title}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Flex px={{ _: 0, md: 4 }} pt={{ _: 3, md: 4 }} pb={4}>
        {typeof activeTab.content === "function" ? (
          <activeTab.content {...tabProps} />
        ) : (
          activeTab.content
        )}
      </Flex>
    </>
  );
};

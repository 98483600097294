import React from "react";
import { Text } from "flicket-ui";

export const Badge = ({ children }) => (
  <Text
    style={{
      padding: "4px 8px",
      backgroundColor: "#ce202f",
      borderRadius: 3,
      textTransform: "uppercase",
      fontSize: 11,
      fontWeight: 800,
      fontStyle: "italic",
    }}
    fontSize={1}
    display="inline-block"
    whiteSpace="nowrap"
    lineHeight="normal"
    color="white"
    mr={1}
  >
    {children}
  </Text>
);

import { Release } from "~graphql/fetchers";
import { CurrentOrganizationQuery, EventQuery } from "~graphql/sdk";

// Used to store global state.
// Initially created to store the organization after
// it has been loaded on the server so we don't have to load it on
// every page load.

type StoreProps = {
  organization: CurrentOrganizationQuery["currentOrganization"] | null;
  host: string;
  event?: EventQuery["event"];
  // release?: Release;
};
export const store: StoreProps = {
  organization: null,
  host: "",
  event: null,
  // release: null,
};

import { TicketType } from "~graphql/sdk";

export enum CoverTypes {
  ALL = "all",
  UNCOVERED = "uncovered",
  COVERED = "covered",
}

export enum AlcoholFreeZoneTypes {
  NO_PREFERENCE = "no-preference",
  YES = "yes",
  NO = "no",
}

export enum SortTypes {
  BEST_AVAILABLE = "best-available",
  PRICE = "price",
}

export enum SeatSelectTypes {
  QUICK_SELECT = "quick-select",
  FREE_SELECT = "free-select",
}

export const sortOptions = [
  { value: "best-available", label: "Best available" },
  { value: "price", label: "Price" },
];

export const alcoholFreeZoneOptions = [
  { value: AlcoholFreeZoneTypes.NO_PREFERENCE, label: "No preference" },
  { value: AlcoholFreeZoneTypes.YES, label: "Yes" },
  { value: AlcoholFreeZoneTypes.NO, label: "No" },
];

export const coverTypeOptions = [
  { value: CoverTypes.ALL, label: "No preference" },
  { value: CoverTypes.COVERED, label: "Covered" },
  { value: CoverTypes.UNCOVERED, label: "Uncovered" },
];

export const seatSelectTypeOptions = [
  { value: SeatSelectTypes.QUICK_SELECT, label: "Quick select" },
  { value: SeatSelectTypes.FREE_SELECT, label: "Free select" },
];

export type ZoneCategory = {
  key: string | number;
  tags: string[];
  color: string;
};

export type ZoneSection = {
  categories: ZoneCategory[];
  name: string;
  keys: number[];
  tags: string[];
};

export type ZoneDetails = {
  name: string;
  categories: ZoneCategory[];
  sections: Record<
    string,
    {
      name: string;
      categories: ZoneCategory[];
    }
  >;
};

export type SeatsIOZone = {
  name: string;
  keys: number[];
  tags: string[];
  categories: ZoneCategory[];
  sections: ZoneSection[];
};

export type SeatsIOCategory = {
  label: string;
  color: string;
  accessible: boolean;
  key: number;
  pricing: number;
  isFiltered: boolean;
  hasSelectableObjects: boolean;
};

export type SeatsIOSeat = {
  id: string;
  customId?: string;
  uuid: string;
  label: string;
  selected: boolean;
  status: string;
  category: SeatsIOCategory;
  labels: {
    displayedLabel: string;
    own: string;
    parent: string;
    section: string;
  };
  objectType: string;
  selectable: boolean;
  deselect: (e: any) => void;
  /**
   * after we have selected a ticket type this will be added here through context
   */
  ticketType?: {
    value: TicketType["id"];
    label: TicketType["name"];
  } & any;

  price?: number;
};

import { NextPageContext } from "next";

export const handleNotFoundRedirect = (ctx: NextPageContext) => {
  if (ctx?.res && ctx.pathname !== "/not-found") {
    ctx.res.writeHead(302, {
      Location: "/not-found",
    });
    return ctx.res.end();
  }

  return {
    props: {},
  };
};

import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "styled-components";

export const useIsMobile = () => {
  const theme = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIfMobile = () =>
      setIsMobile(
        window.innerWidth < Number(theme.breakpoints.md.replace(/\D/g, ""))
      );

    checkIfMobile();

    window.addEventListener("resize", checkIfMobile);
    return () => window.removeEventListener("resize", checkIfMobile);
  }, [theme.breakpoints]);

  return isMobile;
};

import React, { ReactNode } from "react";
import { SystemProps } from "flicket-ui";

import { Auth } from "./layout.Auth";
import { Main } from "./layout.Main";
import { Overlay } from "./layout.Overlay";
import { Sub } from "./layout.Sub";

type LayoutProps = SystemProps & {
  layoutType: "main" | "auth" | "sub" | "overlay";
  /** some layouts accept a centered version for desktop */
  isCentered?: boolean;
  children: ReactNode;
};

const layouts = {
  main: Main,
  sub: Sub,
  auth: Auth,
  overlay: Overlay,
};

export const Layout = ({
  layoutType = "main",
  children,
  ...props
}: LayoutProps) => {
  const Layout = layouts[layoutType];

  return (
    <Layout id="my-body" {...props}>
      {children}
    </Layout>
  );
};

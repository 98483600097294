import React, { createContext, useReducer, ReactNode } from "react";
import has from "lodash/has";

import {
  CoverTypes,
  AlcoholFreeZoneTypes,
  SortTypes,
  SeatsIOSeat,
  SeatSelectTypes,
} from "~components/reservation/constants";
import { MembershipType, TicketType, Zone } from "~graphql/sdk";
import * as Sentry from "@sentry/nextjs";

export interface ReservationState {
  seats: SeatsIOSeat[] | undefined;
  selectFailed: boolean;
  holdToken: string | undefined;
  zones: any[] | undefined;
  addons: any;
  options: {
    amount: number | undefined;
    seatSelectType: SeatSelectTypes;
    coverType: CoverTypes;
    zoneType: AlcoholFreeZoneTypes;
    selectedSort: SortTypes;
    selectedZone: any | undefined;
    selectedSection: any | undefined;
    selectBestOnChange: boolean;
    validSeatSelection: boolean;
  };
}

export enum ReservationActionTypes {
  SET_SEAT_TYPE = "SET_SEAT_TYPE",
  SET_SEATS = "SET_SEATS",
  UNSET_SEATS = "UNSET_SEATS",
  SELECT_FAILED = "SELECT_FAILED",
  UPDATE_OPTIONS = "UPDATE_OPTIONS",
  RESET_OPTIONS = "RESET_OPTIONS",
  SET_TOKEN = "SET_TOKEN",
  SET_ZONES = "SET_ZONES",
  UPDATE_ADDON = "UPDATE_ADDON",
}

type ReservationActions =
  | {
      type:
        | ReservationActionTypes.UNSET_SEATS
        | ReservationActionTypes.RESET_OPTIONS;
    }
  | { type: ReservationActionTypes.SET_SEATS; payload: SeatsIOSeat[] }
  | { type: ReservationActionTypes.SET_ZONES; payload: Zone[] }
  | {
      type: ReservationActionTypes.SET_SEAT_TYPE;
      payload: {
        id: string;
        ticketType: { label: string; value: string } & Partial<MembershipType> &
          Partial<TicketType>;
      };
    }
  | { type: ReservationActionTypes.SET_TOKEN; payload: string }
  | {
      type: ReservationActionTypes.UPDATE_ADDON;
      payload: {
        id: string;
        quantity: number;
      };
    }
  | { type: ReservationActionTypes.SELECT_FAILED }
  | {
      type: ReservationActionTypes.UPDATE_OPTIONS;
      payload: Partial<Record<keyof ReservationState["options"], any>>;
    };

interface Props {
  children: ReactNode;
}

interface ReservationContextProps extends ReservationState {
  dispatch: (input: ReservationActions) => void;
}

const initialState: ReservationState = {
  seats: undefined,
  holdToken: undefined,
  zones: undefined,
  addons: {},
  selectFailed: false,
  options: {
    amount: undefined,
    seatSelectType: SeatSelectTypes.QUICK_SELECT,
    coverType: CoverTypes.ALL,
    zoneType: AlcoholFreeZoneTypes.NO_PREFERENCE,
    selectedSort: SortTypes.BEST_AVAILABLE,
    selectedZone: undefined,
    selectedSection: undefined,
    selectBestOnChange: true,
    validSeatSelection: false,
  },
};

function reducer(state: ReservationState, action: ReservationActions) {
  switch (action.type) {
    case ReservationActionTypes.SET_SEATS:
      return { ...state, seats: action.payload, selectFailed: false };
    case ReservationActionTypes.UNSET_SEATS:
      return { ...state, seats: undefined, selectFailed: false };
    case ReservationActionTypes.SET_ZONES:
      return { ...state, zones: action.payload, selectFailed: false };
    case ReservationActionTypes.SET_SEAT_TYPE:
      if (
        !action.payload.ticketType ||
        !action.payload.ticketType?.value ||
        !action.payload.ticketType?.label
      ) {
        Sentry.captureException(
          new Error(
            "ReservationActionTypes.SET_SEAT_TYPE: Attempting to set seat type without a ticket type."
          ),
          {
            tags: {
              action: action.type,
            },
            extra: {
              state: state.seats,
            },
          }
        );
      }

      return {
        ...state,
        seats: state.seats.map((s) => ({
          ...s,
          ...((s.id === action.payload.id ||
            s.customId === action.payload.id) && {
            ticketType: action.payload.ticketType,
          }),
        })),
      };
    case ReservationActionTypes.SET_TOKEN:
      return { ...state, holdToken: action.payload };
    case ReservationActionTypes.UPDATE_OPTIONS: {
      const changedCoverType =
        has(action.payload, "coverType") &&
        state.options.coverType !== action.payload.coverType;
      const changedZoneType =
        has(action.payload, "zoneType") &&
        state.options.zoneType !== action.payload.zoneType;

      return {
        ...state,
        seats: changedCoverType || changedZoneType ? null : state.seats,
        options: {
          ...state.options,
          ...action.payload,
          ...(changedCoverType || changedZoneType
            ? {
                selectedZone: undefined,
                selectedSection: undefined,
              }
            : {}),
          ...(!has(action.payload, "selectBestOnChange")
            ? { selectBestOnChange: true }
            : {}),
        },
      };
    }
    case ReservationActionTypes.UPDATE_ADDON:
      return {
        ...state,
        addons: {
          ...state.addons,
          [action.payload.id]: action.payload.quantity,
        },
      };
    case ReservationActionTypes.SELECT_FAILED: {
      return {
        ...state,
        selectFailed: true,
      };
    }
    case ReservationActionTypes.RESET_OPTIONS: {
      return initialState;
    }
    default:
      throw Error("Action type not defined");
  }
}

const ReservationContext = createContext<ReservationContextProps>(undefined);
ReservationContext.displayName = "ReservationContext";

const ReservationProvider = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <ReservationContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </ReservationContext.Provider>
  );
};

export { ReservationProvider, ReservationContext };
